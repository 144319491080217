#home {
    position: relative;
    background-image: url(../../assets/heropicture3.jpg);
    background-size: cover; //always use cover with an image as a background
    background-repeat: no-repeat;
    background-position: center;

    .app__wrapper {
        padding:0;

        .copyright{
            display:none;
        }
    }
}

.app__header{
    flex:1;
    width:100%;
    height:100%;
    flex-direction: row;


    padding: 6rem 0 0;

    @media screen and (min-width: 2000px){
        padding-top:8rem;
    }
    @media screen and (max-width: 1200px){
        flex-direction: column;
    }
    @media screen and (max-width: 450px){
        padding-top:6rem 1rem 2rem;
    }
}

.app__header-info {
    flex:0.65; // takes 65% space of the screen
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    
    @media screen and (max-width: 2000px){
       width: 100%;
       margin-right: 0rem;
    }
    @media screen and (max-width: 450px){
        margin-left: 2rem;
    }
}

.app__header-badge {
    width: 100%;
    display:flex;
    justify-content: flex-end;
    align-items:flex-end;
    flex-direction: column;


    .badge-cmp, .tag-cmp{
        padding:1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        background-color: var(--primary-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
    .tag-cmp{
        flex-direction: column;
        margin-top:3rem;

        p {
            width:100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span {
        font-size: 2.5rem;

        @media screen and(min-width: 2000px){
            font-size: 5rem;
        }
    }
    @media screen and(max-width:1200px){
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles{
    flex: 0.75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height:100%;
    margin-left: 1rem;

    div{
        width:100px;
        height:100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

        img {
            width:60%;
            height:60%;
        }
    }

    div:nth-child(1){
        width:100px;
        height: 100px;
    }
    div:nth-child(2){
        margin:1.75rem;
        width:150px;
        height:150px;
    }
    div:nth-child(3){
        width:70px;
        height: 70px;
    }
    @media screen and (max-width: 1200px){
        display: none;
    }

    @media screen and (min-width: 2000px){
        div:nth-child(1){
            width:200px;
            height:200px;
        }
        div:nth-child(2){
            width:300px;
            height:300px;
        }
        div:nth-child(3){
            width:140px;
            height:140px;
        }
    }
    @media screen and (max-width: 1200px){
        width:100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}

.app__header-img{
    flex: 5;
    height:90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    margin: 2rem;
    padding:1rem;
    box-shadow: 0 0 30px rgba(0,0,0,0.2);
    border-radius: 15px;

    .app__header-btn{
        border-style: none;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color:#000;
        font-weight: 550;
        width:400px;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;
        text-decoration: none;
        text-align: center;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }
        z-index: 1;
    }
    @media screen and(max-width:1200px){
        margin: 2rem 0;
    }

}